(function () {
  'use strict';

  angular
    .module('members')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('members', {
        url: '/members',
        views:{
			navigation: {
			   templateUrl: 'navigation/navigation.tpl.html',
			   controller: 'NavigationCtrl',
			   controllerAs: 'navigation'
			},
			alerts: {
			   templateUrl: 'alerts/alerts.tpl.html',
			   controller: 'AlertsCtrl',
			   controllerAs: 'alerts'
			},
			main:{
				templateUrl: 'members/members.tpl.html',
				controller: 'MembersCtrl',
				controllerAs: 'members'
			},
			footer: {
			   templateUrl: 'footer/footer.tpl.html',
			   controller: 'FooterCtrl',
			   controllerAs: 'footer'
			 }
		}
      });
  }
}());
